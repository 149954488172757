<template>
  <div class="flex flex-col relative items-center">
    <ClientOnly>
      <ButtonsIcon
        v-if="canShare"
        :breakpoints="{ lg: 24, md: 20, sm: 20 }"
        outlined
        icon-name="IconShare"
        @click="onShareHandler"
      >
      </ButtonsIcon>
      <TooltipWrapper placement="top" v-else>
        <ButtonsIcon
          :breakpoints="{ lg: 24, md: 20, sm: 20 }"
          outlined
          icon-name="IconShare"
          @click="onShareHandler"
        >
        </ButtonsIcon>
        <template #content>
          <div class="flex flex-row justify-between items-center gap-5">
            <a :href="shareUrls.twitter.href" target="_blank">
              <Icon name="IconSocialTwitter" size="24"></Icon>
            </a>
            <a :href="shareUrls.facebook.href" target="_blank">
              <Icon name="IconSocialFacebookFilled" size="24"></Icon>
            </a>
            <a
              :href="shareUrls.whatsapp.href"
              data-action="share/whatsapp/share"
              target="_blank"
            >
              <Icon name="IconSocialWhatsapp" size="24"></Icon>
            </a>
          </div>
        </template>
      </TooltipWrapper>

      <slot></slot>
    </ClientOnly>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
});

const canShare = computed(() => process.client && navigator.canShare);

const shareUrls = computed(() => {
  return useBuildShareUrls(props);
});

const onShareHandler = async () => {
  const didShare = await useNavigatorShare({
    title: props.title,
    url: props.url,
  });
  if (!didShare) {
    // TODO: handle error
  }
};
</script>
