<template>
  <TooltipWrapper :trigger-id="`wish-${mediaExternalID}`">
    <ButtonsIcon
      v-if="!isAlreadyInWishlist"
      :outlined="outlined"
      :class="btnClass"
      :disabled="isSubmitting"
      :breakpoints="{ lg: 24, md: 20, sm: 20 }"
      iconName="IconPlus"
      @click.stop="onAdd"
    />
    <ButtonsIcon
      v-else
      :outlined="outlined"
      :disabled="isSubmitting"
      :breakpoints="{ lg: 24, md: 20, sm: 20 }"
      iconName="IconChecked"
      class="variant-secondary outlined"
      @click.stop="onRemove"
    />
    <template #content>
      <span
        class="w-full h-full text-title-medium text-white flex justify-center items-center"
      >
        {{
          $t(!isAlreadyInWishlist ? "Add to my list" : "Remove from my list")
        }}
      </span>
    </template>
  </TooltipWrapper>
</template>

<script setup>
import { ref } from "vue";
import { useWishlistStore } from "@/stores/wishlist";
import { useSessionStore } from "@/stores/session";
const sessionStore = useSessionStore();

const { isUnAuthPopup } = useNeedLoginPopup();
const wishlistStore = useWishlistStore();
onMounted(() => {
  if (sessionStore.activeProfile) {
    wishlistStore.fetchWishlistOnce();
  }
});

const { openToast } = useToast();

const isSubmitting = ref(false);

const { media } = defineProps({
  media: {
    type: Object,
    required: true,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  btnClass: {
    type: String,
    default: "variant-secondary outlined",
  },
});

const isAlreadyInWishlist = computed(() => {
  return wishlistStore.getWishlistSet.has(mediaExternalID.value);
});

const mediaExternalID = computed(() => {
  switch (media.type) {
    case "Series": {
      return (
        media?.tvShowSeries?.seriesExternalId ||
        media?.tvShowSeries?.externalId ||
        media.seriesExternalId
      );
    }
    case "Season": {
      return media?.tvShowSeason?.seriesExternalId || media?.seriesExternalId;
    }
    case "Movie": {
      return (
        media.assetExternalId ||
        media.externalId ||
        media?.aggregatedVideo?.assetExternalId
      );
    }

    default: {
      return media.externalId;
    }
  }
});

const mediaExternalIDForRemove = computed(() => {
  if (!isAlreadyInWishlist.value) return "";

  // Compare this Item against Wishlist
  const findItemInWishlist = wishlistStore.getWishlist.find((item) => {
    switch (media.type) {
      case "Series": {
        return item?.externalSeriesId === mediaExternalID.value;
      }
      case "Season": {
        return item?.externalSeriesId === mediaExternalID.value;
      }

      default: {
        return false;
      }
    }
  });
  // Return id for Remove API depending on type
  if (!findItemInWishlist) {
    return mediaExternalID.value;
  } else {
    return findItemInWishlist?.externalContentId;
  }
});

//--------- Helpers ---------

// on Add
const onAdd = async (event) => {
  if (isUnAuthPopup()) return;
  if (!mediaExternalID.value) return;
  event.preventDefault();
  isSubmitting.value = true;

  const response = await wishlistStore.onAdd(mediaExternalID.value);

  if (!response.status == 200 || !response.code == "SUCCESS") {
    openToast({ content: msg });
  }
  isSubmitting.value = false;
};

// On remove
const onRemove = async (event) => {
  if (isUnAuthPopup()) return;
  if (!mediaExternalIDForRemove.value) return;
  event.preventDefault();
  isSubmitting.value = true;

  const response = await wishlistStore.onRemove(mediaExternalIDForRemove.value);

  if (!response.status == 200 || !response.code == "SUCCESS") {
    openToast({ content: msg });
  }

  isSubmitting.value = false;
};
</script>
