export default function () {
  const fallbackGraphic = computed(() => {
    return "/static/pngs/reel.png";
  });

  const style = {
    background: "#353535",
    backgroundImage: `url('${fallbackGraphic.value}') `,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "72px",
  };

  return {
    style,
    url: fallbackGraphic,
  };
}
